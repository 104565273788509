







































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import RadioButtonGroup from '@/components/molecules/RadioButtonGroup.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    RadioButtonGroup,
  },
})
export default class Transfer extends Mixins(LocalMoment, ClassModeMethods, ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private transferDatas: { type: string; items: { key: number; label: string }[] }[] = [
    {
      type: '集団',
      items: [],
    },
    {
      type: '個別',
      items: [],
    },
  ]

  private subjects = [
    { key: 'ko', label: '国語' },
    { key: 'su', label: '算数/数学' },
    { key: 'ei', label: '英語' },
    { key: 'ri', label: '理科' },
    { key: 'sh', label: '社会' },
  ]

  private uncheckedColor = 'var(--color-blue-1)'
  private checkedColor = 'var(--color-white)'

  private groupClassLessonIds: number[] = []

  private subjectDisabled = true

  private selectedLesson = ''

  private selectedSubject = ''

  private isProcessing = false

  private formatedDate = this.today().format('M月D日 dddd')

  private paramsDate = this.today().format('YYYY-MM-DD')

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadLessonsTransfer()
    Vue.prototype.$loading.complete()
  }

  @Watch('selectedLesson')
  onSelectedLessonChange(newLesson: number) {
    if (this.groupClassLessonIds.includes(newLesson)) {
      this.saveLessonTransfer()
    } else {
      this.subjectDisabled = false
    }
  }

  @Watch('selectedSubject')
  onSelectedSubjectChange() {
    this.saveLessonTransfer()
  }

  private async saveLessonTransfer() {
    Vue.prototype.$loading.start()
    await this.createLessonUser()
    Vue.prototype.$loading.complete()
  }

  private async loadLessonsTransfer() {
    await Vue.prototype.$http.httpWithToken
      .get('/lessons/transfer', {
        params: {
          userId: this.userId,
          date: this.paramsDate,
        },
      })
      .then((res: any) => {
        res.data.forEach((lesson: { lessonId: number; isGroup: boolean; className: string }) => {
          if (lesson.isGroup) {
            this.transferDatas[0].items.push({ key: lesson.lessonId, label: lesson.className })
            this.groupClassLessonIds.push(lesson.lessonId)
          } else {
            this.transferDatas[1].items.push({ key: lesson.lessonId, label: lesson.className })
          }
        })
      })
  }

  private async createLessonUser() {
    if (this.isProcessing === true) return
    this.isProcessing = true
    await Vue.prototype.$http.httpWithToken
      .post('/lessonUsers', {
        userId: this.userId,
        lessonId: this.selectedLesson,
        subjectCode: this.selectedSubject,
      })
      .then(() => {
        this.$router.push('/student/dashboard')
      })
      .catch(() => {
        alert('振替えに失敗しました。ページを更新してやり直してください。')
      })
      .finally(() => {
        this.isProcessing = false
      })
  }
}
