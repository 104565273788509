




















import { Component, Prop, Vue } from 'vue-property-decorator'
import RadioBase from '@/components/atoms/RadioBase.vue'

@Component({
  components: {
    RadioBase,
  },
  inheritAttrs: false,
})
export default class RadioButtonGroup extends Vue {
  @Prop()
  name!: string

  @Prop()
  items!: { label: string; key: string | number }[]

  @Prop()
  checked!: string | number

  @Prop({ default: false })
  disabled?: boolean

  @Prop()
  width?: string

  @Prop()
  height?: string

  @Prop()
  uncheckedColor!: string

  @Prop()
  checkedColor!: string

  private value: string | number = this.checked

  private onChange(value: string | number): void {
    this.$emit('input', value)
  }

  get setStyleInfo(): { [key: string]: string } {
    return {
      '--width': this.width ? this.width : 'auto',
      '--height': this.height ? this.height : 'auto',
      '--unchecked_color': this.uncheckedColor,
      '--checked_color': this.checkedColor,
    }
  }
}
